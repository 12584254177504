exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-now-js": () => import("./../../../src/pages/book-now.js" /* webpackChunkName: "component---src-pages-book-now-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-benefits-of-being-diagnosed-with-adhd-in-adulthood-mdx": () => import("./../../../src/pages/insights/benefits-of-being-diagnosed-with-adhd-in-adulthood.mdx" /* webpackChunkName: "component---src-pages-insights-benefits-of-being-diagnosed-with-adhd-in-adulthood-mdx" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-insights-mdx-frontmatter-slug-js-content-file-path-src-pages-insights-adhd-in-adulthood-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/pages/insights/adhd-in-adulthood.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-js-content-file-path-src-pages-insights-adhd-in-adulthood-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-js-content-file-path-src-pages-insights-adhd-more-likely-to-be-missed-in-women-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/pages/insights/adhd-more-likely-to-be-missed-in-women.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-js-content-file-path-src-pages-insights-adhd-more-likely-to-be-missed-in-women-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-js-content-file-path-src-pages-insights-benefits-of-being-diagnosed-with-adhd-in-adulthood-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/pages/insights/benefits-of-being-diagnosed-with-adhd-in-adulthood.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-js-content-file-path-src-pages-insights-benefits-of-being-diagnosed-with-adhd-in-adulthood-mdx" */),
  "component---src-pages-services-adhd-assessments-for-young-people-js": () => import("./../../../src/pages/services/adhd-assessments-for-young-people.js" /* webpackChunkName: "component---src-pages-services-adhd-assessments-for-young-people-js" */),
  "component---src-pages-services-adult-adhd-assessments-js": () => import("./../../../src/pages/services/adult-adhd-assessments.js" /* webpackChunkName: "component---src-pages-services-adult-adhd-assessments-js" */),
  "component---src-pages-services-adult-autism-assessments-js": () => import("./../../../src/pages/services/adult-autism-assessments.js" /* webpackChunkName: "component---src-pages-services-adult-autism-assessments-js" */),
  "component---src-pages-services-autism-assessments-for-young-people-js": () => import("./../../../src/pages/services/autism-assessments-for-young-people.js" /* webpackChunkName: "component---src-pages-services-autism-assessments-for-young-people-js" */),
  "component---src-pages-services-psychological-therapy-js": () => import("./../../../src/pages/services/psychological-therapy.js" /* webpackChunkName: "component---src-pages-services-psychological-therapy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-visual-language-js": () => import("./../../../src/pages/visual-language.js" /* webpackChunkName: "component---src-pages-visual-language-js" */)
}

